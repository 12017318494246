<template>
	<Modal
		:value="visible"
		title="表单设计"
		width="1200"
		:styles="{ top: '40px' }"
		:scrollable="true"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div class="ctms-form-design" :style="{ height: contentHeight + 'px' }">
			<div class="form-component-group">
				<h2>表单组件</h2>
				<Input
					icon="ios-search"
					placeholder="过滤表单组件"
					clearable
					class="filter-search"
					v-model="filterData"
					:search="true"
				/>
				<div class="component-list">
					<ul>
						<li
							v-for="item in noChosenList"
							:key="item.id"
							@click="handlenoChosenItemClick(item)"
						>
							<Tooltip>
								<div slot="content">
									<p style="white-space: normal">
										{{ item.tag ? item.tag + " -- " : item.tag }}{{ item.name }}
									</p>
								</div>
								<Button
									style="
										width: 280px;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									"
								>
									{{ item.tag ? item.tag + " -- " : item.tag
									}}{{ item.name }}</Button
								>
							</Tooltip>
						</li>
					</ul>
				</div>
			</div>
			<div class="form-component-group">
				<h2>已选组件</h2>
				<designTemplate
					ref="designDrag"
					:componentList="componentList"
					showType="design"
					:isBackFill="true"
					@onDeleItem="handleDeleChosen"
				></designTemplate>
			</div>
		</div>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
// import FormDesign from '@/components/BusinessComponents/FormDesign/index.vue';
import designTemplate from "@/components/BusinessComponents/FormDesign/designTemplate.vue"
import api from "@/api/setting/form"
import formItemApi from "@/api/setting/formItem"
import { mapState } from "vuex"

const { apiGet, apiFormSubmit } = api
const { getFormItemList } = formItemApi
export default {
	name: "FormDesign",
	components: {
		designTemplate
	},
	props: ["visible", "id"],
	data() {
		return {
			loading: false,
			formItemList: [],
			allFormItemList: [],
			componentList: [],
			filterData: ""
		}
	},
	computed: {
		noChosenList() {
			if (!this.filterData) {
				return this.formItemList
			}
			return this.formItemList.filter(
				item =>
					item.name.indexOf(this.filterData) > -1 ||
					item.tag.indexOf(this.filterData) > -1
			)
		},
		...mapState({
			contentHeight: state => state.contentHeight - 120
		})
	},
	watch: {
		visible() {
			if (this.visible && this.id) {
				this.componentList = []
				this.getList()
			}
		}
	},
	methods: {
		async getList() {
			this.loading = true
			const res1 = await apiGet({ id: this.id })
			if (res1) {
				const { json } = res1.data
				if (json) {
					this.componentList = JSON.parse(json)
				}
			}
			const res2 = await getFormItemList({ del: 0 })
			if (res2) {
				const { data } = res2
				this.allFormItemList = data
				const formItemList = data
				this.formItemList = formItemList.filter(item => {
					const flag = this.componentList.every(comp => comp.id !== item.id)
					return flag
				})
			}
			this.loading = false
		},
		handlenoChosenItemClick(formItem) {
			// 左侧添加到右侧
			this.$refs.designDrag.onAddFormItem(formItem)
			this.formItemList = this.formItemList.filter(
				item => item.id !== formItem.id
			)
		},
		handleDeleChosen(formItem) {
			//  右侧删除组件到左侧
			const newItem = this.allFormItemList.find(item => item.id === formItem.id)
			this.formItemList.push(newItem)
		},
		onCancel() {
			this.$emit("onCancel")
		},
		async onOk() {
			this.loading = true
			const { list } = this.$refs.designDrag
			const data = list.map(item => {
				const obj = {}
				Object.keys(item).forEach(key => {
					if (key === "json") {
						return
					}
					obj[key] = item[key]
				})
				return obj
			})
			const res = await apiFormSubmit(
				{
					componentIds: data.map(item => item.id),
					json: data
				},
				this.id
			)
			if (res) {
				this.$emit("onCancel")
				this.$Message.success("操作成功！")
			}
			this.loading = false
		}
	}
}
</script>

<style scoped lang="less">
.ctms-form-design {
	height: 100%;
	display: flex;
	.form-component-group {
		.component-list {
			padding: 0 15px;
			overflow-y: auto;
			height: calc(~"100% - 60px");
		}
		.filter-search {
			padding: 10px;
		}
		h2 {
			font-size: 16px;
			padding: 0 10px;
		}
		&:first-child {
			border-right: 1px solid #ddd;
			width: 350px;
		}
		&:last-child {
			flex: 1;
			overflow: auto;
			h2 {
				color: #f60;
				font-size: 20px;
			}
		}
		ul {
			list-style: none;
			li {
				margin-top: 16px;
			}
		}
	}
}
</style>
