import request from "@/utils/request"

// const moduleName = '/common/form-template';

export default {
	// 新增
	addFormItem: data =>
		request("/common/form-component", {
			method: "post",
			body: data
		}),
	updateFormItem: data =>
		request(`/common/form-component/${data.id}`, {
			method: "put",
			body: data
		}),
	// 获取表单组件信息详情
	getDetail: id =>
		request(`/common/form-component/${id}`, {
			method: "get"
		}),
	// 禁用/启用表单组件
	enableDisabled: id =>
		request(`/common/form-component/${id}/enabled-disabled`, {
			method: "put"
		}),
	// 获取表单组件列表(分页)
	getFormItemListByPage: params =>
		request("/common/form-components-page", {
			method: "get",
			params
		}),
	// 获取表单组件列表
	getFormItemList: params =>
		request("/common/form-components", {
			method: "get",
			params
		}),
	// 获取表单组件列表
	getRequestList: () =>
		request("/common/form-component-interfaces", {
			method: "get"
		})
}
