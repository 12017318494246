<template>
	<Modal
		title="绑定文件"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="是否绑定文件" prop="isBind">
						<RadioGroup v-model="formData.isBind">
							<Radio :label="1">是</Radio>
							<Radio :label="0">否</Radio>
						</RadioGroup>
					</FormItem>
				</Col>
				<Col span="24" v-if="formData.isBind === 1">
					<FormItem label="绑定文件" prop="bindFileId">
						<Select v-model="formData.bindFileId">
							<Option
								v-for="item in fileList"
								:value="item.attachmentId"
								:key="item.attachmentId"
							>
								{{ item.filename }}
							</Option>
						</Select>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/form"

const { apiBindFile } = api

export default {
	name: "FormBindFile",
	props: ["visible", "bindObj", "fileList"],
	data() {
		return {
			loading: false,
			formData: {
				isBind: 1,
				bindFileId: ""
			},
			title: "",
			rules: {
				isBind: {
					required: true,
					message: "是否绑定文件不能为空",
					trigger: "blur",
					type: "number"
				},
				bindFileId: {
					required: true,
					message: "绑定文件不能为空",
					trigger: "change blur"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {
					isBind: 1,
					bindFileId: ""
				}
				if (this.bindObj.id) {
					this.formData = {
						isBind: this.bindObj.isBind,
						bindFileId: this.bindObj.bindFileId || ""
					}
					/* this.$asyncDo(async () => {
            this.loading = true;
            const res = await apiGet({ id: this.id });
            if (res) {
              this.formData = {
                ...this.formData,
                ...res.data,
              };
            }
            this.loading = false;
          }); */
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = null
						const formData = { ...this.formData }
						res = await apiBindFile({
							...formData,
							bindFileId: this.formData.isBind ? this.formData.bindFileId : "",
							id: this.bindObj.id
						})
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style scoped></style>
