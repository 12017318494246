<template>
	<Modal
		:title="title"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="表单名称" prop="name">
						<Input v-model="formData.name" placeholder="请输入表单名称" />
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="描述" prop="descr">
						<Input
							v-model="formData.descr"
							type="textarea"
							placeholder="请输入描述"
							:rows="5"
						/>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/form"

const { apiGet, apiAdd, apiUpdate, apiCopy } = api

export default {
	name: "FormAdd",
	props: ["visible", "id", "isCopy"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rules: {
				name: { required: true, message: "表单名称不能为空", trigger: "blur" }
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {}
				this.title = "新增表单"
				if (this.id) {
					this.title = "修改表单"
					if (this.isCopy) {
						this.title = "复制表单"
					}
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.id })
						if (res) {
							this.formData = {
								...this.formData,
								...res.data
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						const formData = { ...this.formData }
						delete formData.id
						if (this.isCopy) {
							res = await apiCopy({
								name: formData.name,
								id: this.id
							})
						} else if (this.id) {
							res = await apiUpdate({
								...formData,
								id: this.id
							})
						} else {
							res = await apiAdd({
								...formData
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style scoped></style>
